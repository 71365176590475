import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지엑스, 서비스 생태계 안착 시기까지 전 가입 고객 대상 28GHz 5G 데이터 무료제공",
  "| 25년 상반기 런칭 목표… 지하철부터 핫스팟 구축 검토 중",
]

const content = () => (
  <div>
    <br />

    <p>
      스테이지엑스(대표 서상원)는 내년 상반기 중 전 가입 고객을 대상으로, 차별화된 통신 경험을 위해 28Ghz 서비스 생태계가 시장에
      안착할 때까지 핫스팟 내 해당 주파수 기반 데이터를 무료로 제공할 예정이라고 1일 밝혔다.
      <br />
      <br />
      스테이지엑스의 이러한 행보는 딥테크 기반의 새로운 통신사로 시장에 진출하는 만큼, 보다 많은 고객이 새로운 기술과 파격적인
      혜택을 경험할 수 있도록 하기 위함이다. 따라서 스테이지엑스 가입 고객은 2025년 상반기 부터 28GHz 핫스팟 지역 내에서 기존
      5G보다 현저히 빠른 속도의 리얼 5G를 무료로 사용할 수 있게 된다.
      <br />
      <br />
      해당 서비스 이용이 가능한 장소는 지하철, 공항, 공연장 및 인구 밀집지역과 같이 주파수 회절과 반사가 적어 28GHz 주파수
      활용성이 뛰어난 지역이다. 특히 지하철의 경우, 서울 교통이용 통계보고서에 따르면 일평균 이용건수는 565만 5천건, 이용시간은
      35.8분으로 이용자와 체류시간 모두 높은 지역이다. 스테이지엑스는 지하철의 28GHz 백홀 와이파이 구현을 위한 기지국 설치
      우선순위를 높여 검토 중이다.
      <br />
      <br />
      스테이지엑스 관계자는 “3년 내 예상 가입자는 약 300만명으로 많은 소비자들이 차별화된 통신 경험과 혜택을 받게 될 것”이라며
      “지하철 내28GHz 백홀 와이파이를 구축하고, 28GHz 가능 단말 도입 확대를 위해 정부 및 주요 단말 제조사와 긴밀하게 공조해
      커버리지를 극대화할 예정이다. 28GHz 서비스 활성화에 전환점이 될 것”이라 밝혔다.
      <br />
      <br />
      스테이지엑스만의 차별화된 리얼 5G를 선보인다는 전략은 28GHz 주파수 특성에서 그 핵심을 엿볼 수 있다. 해당 주파수는 현재의
      중저대역 5G의 약 100MHz 대역폭 보다 8배 넓은 800MHz의 초광대역폭이다. 때문에 기존 5G보다 월등한 통신속도 구현이 가능하다.
      대역폭을 흔히 차선에 비교하여 설명하는데, 스테이지엑스가 확보한 대역폭은 8차선 고속도로에 비유할 수 있다. 대역폭이 넓을 수록
      보낼 수 있는 데이터의 양이 늘어난다는 것이다. 스테이지엑스는 일반 5G 대비 초고속, 초저지연, 초연결의 특성을 가진
      고주파대역의 5G 28GHz 주파수를 독점 낙찰받으며 ‘리얼 5G’를 선보이겠다는 의지를 밝힌 바 있다. 스테이지엑스는 28GHz 주파수의
      국내 독점권을 확보한 만큼, mmWave 기술 및 생태계를 동시 선도하고 이를 통해 시장에 조기 안착할 수 있도록 최선을 다할
      예정이다.
      <br />
      <br />
      28GHz 주파수를 활용한 5G 서비스를 제공할 수 있는 사업자는 현재 스테이지엑스가 유일한 만큼, 인프라 구축을 서두르는 한편 28GHz
      관련 통신 기술과 서비스의 저변 확대를 위한 생태계도 육성할 계획이다.
      <br />
      <br />
      스테이지엑스측 관계자는 "스테이지엑스가 최우선으로 추구하는 것은 ‘고객경험’이다. 리얼5G를 통해 소비자의 통신경험을
      혁신하면서 동시에 실질적인 혜택을 드리고자 한다"며, "기존 5G 대비 월등한 통신 경험을 제공할 수 있을 것”이라고 말했다.
      <br />
      <br />
    </p>
  </div>
)

const press53 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2024년 04월 01일 즉시배포 가능"
      pageInfo=""
      title="스테이지엑스, 28GHz 리얼 5G 데이터 ‘무료제공’ 계획 밝혀"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press53
